<template>
    <v-card>
        <v-card-title >
            <s-toolbar dark color="primary" label="Busqueda de Pallet en camaras"></s-toolbar>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="auto">
                    <s-text v-model="SearchPallet.PalletGroupID" label="Codigo de Pallet"
                    @input="clickSearck()"
                    ></s-text>
                    <!-- @keyup.enter.native="clickSearck(SearchID)" -->
                </v-col>
                <v-col cols="auto">
                    <s-select-client
                        clearable
                        label="Cliente"
                        v-model="SearchPallet.CumID"
                        ref="txtCumID"
                        full
                        @input="clickSearck()"
                    >
                    </s-select-client>
                </v-col>                
                <v-col cols="auto">                              
                    <s-select-definition
                        clearable
                        label="Tipo corte"
                        :def="1429"
                        v-model="SearchPallet.TypeCut"
                        @input="clickSearck()"
                    ></s-select-definition>    
                </v-col>
                <v-col cols="auto">
                    <s-select-definition
                        clearable
                        label="Tipo Empaque"
                        v-model="SearchPallet.TypePacking"
                        :def="1435"
                        @input="clickSearck()"
                    ></s-select-definition>
                </v-col>
                <v-col cols="auto">
                    <s-text v-model="SearchPallet.TraceabilityID" label="Trazabilidad"
                        @input="clickSearck()"
                    ></s-text>
                </v-col>
                <v-col cols="auto">
                    <s-select-definition v-model="TypeProduction" 
                        label="T. Producción"
						:def="1464" 
                        @input="GetListEstadoFinal($event)">
                    </s-select-definition>
                </v-col>
                <v-col cols="auto">
                    <s-select v-model="SearchPallet.StateFinal"
                        label="Estado Final"
                        item-value="TsfID"
                        item-text="TsfDescription"
                        :items="ListEstadoFinal"
                        @input="clickSearck()">
                    </s-select>
                </v-col>
                <v-col cols="auto">
                    <v-btn small 
                        fab 
                        depressed  
                        color="primary" 
                        dark class="mt-1"
                        @click="clickSearck()">
                        <v-icon >mdi-eye</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-data-table dense 
                        :items="PalletFound"
                        :headers="headersPallet" 
                        :items-per-page="-1"
                        disable-sort>
                    </v-data-table>
                </v-col>
            </v-row>
            
        </v-card-text>
    </v-card>
</template>

<script>
    import _sFrzChamberIncomePallet from "@/services/FrozenProduction/FrzIncomePalletChamber.js";
    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
    import _typestatefinal from "@/services/FrozenProduction/FrzTypeStateFinal.js";  

    export default {
        components: {SSelectClient},
        data(){
            return {
                headersPallet: [
                    { text: "Pallet ID", value: "PalletGroupID"},
                    { text: "Lote", value: "Lote" },
                    { text: "Trazabilidad", value: "TraceabilityIDs" },
                    { text: "Cliente", value: "CumNombre"},
                    { text: "Marca", value: "BransName"},
                    { text: "Variedad", value: "VrtDescription"},
                    { text: "Corte", value: "TypeCutName"},
                    { text: "Presentacion", value: "TypePackingName"},
                    { text: "Cajas", value: "NumberBoxes"},
                    { text: "E. Final", value: "StateFinalString"},
                    // { text: "Codigos", value: "RcfIDs"},              
                   
                    { text: "Fruta", value: "TypeCultiveName"},
                    
                    { text: "Localizacion", value: "PalletLocation"},
                    
                    // { text: "Variante", value: "TypeVariantString"},
                    // { text: "Tipo Produccion", value: "TypeProductiontString"},         
                    
                    { text: "T. Congelamiento", value: "CecDescription"},
                    { text: "Condicion", value: "IsObservedString"},
                   
                ],
                PalletFound: [],
                SearchPallet:{
                    PalletGroupID: 0,
                    CumID:0,
                },
                ListEstadoFinal: [],
                TypeProduction: 1
                
               
                
            }
        },
        methods:{
            clickSearck()
            {                
                _sFrzChamberIncomePallet.SearchPallet(this.SearchPallet, this.$fun.getUserID()).then((r) => {    
                    if(r.status == 200)
                    {
                        this.PalletFound = r.data;                            
                    }
                });                                       
            },
            GetListEstadoFinal(){
                _typestatefinal.list({DedID : this.TypeProduction, Position : 2}, this.$fun.getUserID()).then( r => {
                    if(r.status == 200)
                    {
                        this.ListEstadoFinal = r.data;
                        this.ListEstadoFinal.forEach((element, index) => {
                            element.ID = index + 1;
                        });
                        
                    }
                })
            },
        },
        mounted(){                    
            // this.GetListEstadoFinal;                    
        },
    }
</script>