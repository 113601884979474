import Service from "../Service";
const resource = "FrzIncomePalletChamberArea/";

export default {

    pagination(parameters, requestID) {        
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(abt, requestID){
        return Service.post(resource + "save", abt,{
            params: { requestID: requestID }
        });
    },
    update(abt, requestID){
        return Service.post(resource + "update", abt,{
            params: { requestID: requestID }
        });
    },

    sendRepackaging(abt, requestID){
        return Service.post(resource + "sendRepackaging", abt,{
            params: { requestID: requestID }
        });
    },
    
    returnPalett(pal, requestID){
        return Service.post(resource + "returnPallet", pal,{
            params: { requestID: requestID }
        });
    },

    SearchPallet(pal, requestID){
        return Service.post(resource + "searchpallet", pal,{
            params: { requestID: requestID }
        });
    }
}


